<template>
  <div>
    <!-- <navi-gation /> -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('System.LinuxConfig.LinuxArmApp')" name="1">
        <LinuxArmApp :key="timer1"></LinuxArmApp>
      </el-tab-pane>
      <el-tab-pane :label="$t('System.LinuxConfig.LinuxX86app')" name="2">
        <LinuxX86app :key="timer2"></LinuxX86app>
      </el-tab-pane>
      <el-tab-pane :label="$t('System.LinuxConfig.LinuxConfig')" name="3">
        <LinuxConfig :key="timer3"></LinuxConfig>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import LinuxArmApp from './components/LinuxConfig/LinuxArmApp.vue'
import LinuxX86app from './components/LinuxConfig/LinuxX86app.vue'
import LinuxConfig from './components/LinuxConfig/LinuxConfig.vue'
export default {
  components: { LinuxArmApp,LinuxX86app,LinuxConfig },
  data() {
    return {
      timer1: '',
      timer2: '',
      timer3: '',
      activeName: '1'
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleLoad(data) {
      let name = data.name
      if (name == 1) {
        this.timer1 = new Date().getTime()
      } else if (name == 2) {
        this.timer2 = new Date().getTime()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
.editbox {
  padding-top: 15px;
  padding-bottom: 25px;
  margin: 0 10px;
  margin-bottom: 0;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000 !important;
}
.el-input--small .el-input__inner {
  height: 28px;
}
.star {
  color: red;
}
.leftspace {
  display: flex;
  width: 100%;
  .el-input {
    width: 200px;
    margin-right: 5px;
  }
}
.adio {
  margin-left: 100px;
}
.inpbox {
  margin-left: 100px;
  padding-top: 8px;
}
.group {
  padding-top: 8px;
}
.spans {
  width: 72px;
}
.tabs {
  height: 477px;
  overflow: auto;
  border: 1px solid #dddd;
  margin-left: 10px;
  margin-top: 30px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  border-radius: 5px;
}
.btn {
  margin-left: 20px;
  width: 84px;
  color: #fff;
  border: none;
  background: #4787de;
  cursor: pointer;
  border-radius: 3px;
}
.el-checkbox__label {
  line-height: 13px;
}
.mock {
  line-height: 24px;
}
.breadtitle {
  margin-left: 10px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}
.el-tabs__item.is-active {
  color: #000;
}
.btnBox {
  margin-top: 20px;
  margin-left: 174px;
  height: 30px;
  padding-bottom: 25px;
}
#success {
  .el-dialog {
    border-radius: 6px;
    .el-dialog__header {
      padding: 6px;
      background: #1e89e5;
      font-size: 18px;
      text-decoration: none;
      border-radius: 6px 6px 0 0;
      .el-dialog__title {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
      .el-dialog__headerbtn {
        top: 10px !important;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff !important;
      }
    }
    .el-dialog__body {
      padding: 30px 10px !important;
      text-align: center !important;
    }
    .el-dialog__footer {
      padding: 5px 20px !important;
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-radius: 0 0 6px 6px;
    }
  }
}
.el-button--primary {
  background-color: #1e89e5 !important;
  border-color: #1e89e5 !important;
}
.el-message-box  {
  width: 240px;
}
.el-message-box__header  {
  background: #1e89e5;
}
.el-message-box__headerbtn  {
  top: 9px;
}
.el-icon-close:before  {
  content: "\e6db";
  color: white;
}
</style>
<style>
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
.el-tabs__item.is-active {
  color: #000;
}
.el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
.el-form-item__label {
  padding: 0 0px 0 0 !important;
}
.el-tabs__header {
  margin: 0 0 10px !important;
}
</style>
</style>